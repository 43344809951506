import React from 'react';
import {Navbar, Container} from 'react-bootstrap';

import {useAuth} from "../hooks/useAuth";
import {firstName} from "../helpers/utils";
const AppFooter = () => {
    const {user} = useAuth();

    return (
        <Navbar className="bg-body border-top border-1" fixed="bottom">
            <Container fixed="bottom">
                    <small>&copy;TokSaúde</small>
                    <small className="ms-auto">Usuário: {firstName(user.FullName)}</small>
            </Container>
        </Navbar>
    )
};

export default AppFooter;
