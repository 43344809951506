import React from 'react';
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const AppTooltip = ({children, ...rest}) => {
    const {id, title} = rest;
    return <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
        {children}
    </OverlayTrigger>
};

export default AppTooltip;