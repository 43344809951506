import React from 'react';
import {Navbar, Container, Nav, NavDropdown} from 'react-bootstrap';
import {Link} from "react-router-dom";

import logo from '../../src/assets/images/logo.png';
import {FaUser} from "react-icons/fa";
import {useAuth} from "../hooks/useAuth";

const AppNavBar = () => {
    const {logout} = useAuth();

    const handleLogout = () => {
        logout();
    };

    return (
        <Navbar expand="lg" className="bg-body border-bottom border-1" fixed="top">
            <Container>
                <Navbar.Brand href="/">
                    <img src={logo}
                         height="30"
                         className="d-inline-block align-top"
                         alt="Logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className={"border-0"}/>
                <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav>
                        <Nav.Link as={Link} to="/">Início</Nav.Link>
                        <Nav.Link as={Link} to="/atendimento/pa">Atendimento</Nav.Link>
                        <Nav.Link as={Link} to="/atendimento/historico">Histórico</Nav.Link>
                        <NavDropdown title={<FaUser/>} id="basic-nav-dropdown">
                            <NavDropdown.Item as={Link} to="/conta/perfil">Meu perfil</NavDropdown.Item>
                            <NavDropdown.Divider/>
                            <NavDropdown.Item onClick={handleLogout}>Sair</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
};

export default AppNavBar;