import React, {useState} from 'react';
import {Col, FloatingLabel, Form, Row, Spinner} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import api from "../../helpers/api";
import {useAuth} from "../../hooks/useAuth";
import {useToast} from "../../hooks/useToast";

const CredentialForm = () => {
    const toast = useToast();
    const {user} = useAuth();
    const [isLoading, setLoading] = useState(false);

    const {values, touched, errors, handleSubmit, handleChange, handleBlur, isSubmitting} = useFormik({
        initialValues: {
            login: user.Login,
            newPassword: '',
            password: '',
        },
        validationSchema: Yup.object({
            newPassword: Yup.string().required('Preenchimento requerido'),
            password: Yup.string().required('Preenchimento requerido'),
        }),
        onSubmit: async (v) => {
            try {
                setLoading(true);
                await api.put(`/app/usuario/${user.Id}/senha/trocar`, {
                    newPassword: v.newPassword,
                    password: v.password,
                });
                toast.showToast('Senha alterada com sucesso!', 'success');
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        },
    })

    return (
        <>
            <p>
                Para garantir a segurança de sua conta você pode redefina sua senha de acesso sempre sentir que ela
                foi comprometida.
            </p>
            <Row className="justify-content-center mt-4">
                <Col lg={6}>
                    <Row className="mb-3 g-2">
                        <Col xs={12}>
                            <FloatingLabel label={"Login"}>
                                <Form.Control disabled value={values.login} placeholder=""/>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12}>
                            <FloatingLabel label={"Nova senha"}>
                                <Form.Control id="newPassword"
                                              type="password"
                                              value={values.newPassword}
                                              isInvalid={errors.newPassword && touched.newPassword}
                                              placeholder=""
                                              onBlur={handleBlur}
                                              onChange={handleChange}/>
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.newPassword}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                        <Col xs={12}>
                            <FloatingLabel label={"Repetir a senha"}>
                                <Form.Control id="password"
                                              type="password"
                                              value={values.password}
                                              isInvalid={errors.password && touched.password}
                                              placeholder=""
                                              onBlur={handleBlur}
                                              onChange={handleChange}/>
                                <Form.Control.Feedback type="invalid" tooltip>
                                    {errors.password}
                                </Form.Control.Feedback>
                            </FloatingLabel>
                        </Col>
                    </Row>
                    <div className="d-grid mb-5">
                        <Button variant="primary"
                                size="lg"
                                disabled={isSubmitting}
                                onClick={handleSubmit}>
                            {isLoading ? <Spinner/> : 'Redefinir senha'}
                        </Button>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default CredentialForm;