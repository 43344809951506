import React, {useEffect, useState} from 'react';
import {Col, FloatingLabel, Form, Row, Spinner, Button} from "react-bootstrap";
import moment from "moment";
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useAuth} from "../../hooks/useAuth";
import api from "../../helpers/api";
import InputMask from "react-input-mask";
import {useToast} from "../../hooks/useToast";

const PersonalForm = () => {
    const toast = useToast();
    const {user} = useAuth();
    const [isLoading, setLoading] = useState(false);

    const fetchPerson = async () => {
        try {
            setLoading(true);
            const res = await api({
                method: 'GET',
                url: `assinante/${user.OwnerId}`,
            });
            const {data} = res.data;
            setValues({
                fullName: data.FullName,
                birthDate: data.BirthDate,
                taxNumber: data.TaxNumber,
                genre: data.Genre,
                motherFullName: data.MotherFullName,
                fatherFullName: data.FatherFullName,
                email: data.Contacts.Email,
                phone: data.Contacts.Phone,
                zipCode: data.Address.ZipCode,
                street: data.Address.Street,
                number: data.Address.Number,
                complement: data.Address.Complement,
                neighborhood: data.Address.Neighborhood,
                city: data.Address.City,
                state: data.Address.State
            });
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    }

    const {values, touched, errors, handleSubmit, handleChange, handleBlur, isSubmitting, setValues} = useFormik({
        initialValues: {
            fullName: '',
            birthDate: '',
            taxNumber: '',
            genre: '',
            motherFullName: '',
            fatherFullName: '',
            email: '',
            phone: '',
            zipCode: '',
            street: '',
            number: '',
            complement: '',
            neighborhood: '',
            city: '',
            state: ''
        },
        validationSchema: Yup.object({
            motherFullName: Yup.string().required('Preenchimento requerido'),
            fatherFullName: Yup.string().nullable(),
            phone: Yup.string().required('Preenchimento requerido').length(13, 'Formato inválido'),
            zipCode: Yup.string().required('Preenchimento requerido').length(9, 'Formato inválido'),
            street: Yup.string().required('Preenchimento requerido').min(2, 'Mínimo de 2 caracteres'),
            number: Yup.string().nullable(),
            complement: Yup.string().nullable(),
            neighborhood: Yup.string().required('Preenchimento requerido').min(2, 'Mínimo de 2 caracteres'),
            city: Yup.string().required('Preenchimento requerido').min(2, 'Mínimo de 2 caracteres'),
        }),
        onSubmit: async (v) => {
            try {
                setLoading(true);
                await api.put(`assinante/${user.OwnerId}`, {
                    gender: v.gender,
                    motherFullName: v.motherFullName,
                    fatherFullName: v.fatherFullName,
                    phone: v.phone,
                    address: {
                        zipCode: v.zipCode,
                        street: v.street,
                        number: v.number,
                        complement: v.complement,
                        neighborhood: v.neighborhood,
                        city: v.city,
                        state: v.state,
                    },
                });

                toast.showToast('Dados atualizados com sucesso!', 'success');
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        },
    })

    useEffect(() => {
        fetchPerson().then();
    }, []);

    return (
        <>
            <Row className="mb-3 g-2">
                <Col xs={12} lg={6}>
                    <FloatingLabel controlId="fullName" label={"Nome completo"}>
                        <Form.Control disabled
                                      placeholder=""
                                      value={values.fullName}/>
                    </FloatingLabel>
                </Col>
                <Col xs={6} lg={3}>
                    <FloatingLabel controlId="birthDate" label={"Data de nascimento"}>
                        <Form.Control disabled
                                      placeholder=""
                                      value={moment(values.birthDate).format('DD/MM/YYYY')}/>
                    </FloatingLabel>
                </Col>
                <Col xs={6} lg={3}>
                    <FloatingLabel label={"CPF"}>
                        <Form.Control disabled
                                      placeholder=""
                                      value={values.taxNumber}/>
                    </FloatingLabel>
                </Col>
                <Col xs={12} lg={2}>
                    <FloatingLabel controlId="genre" label={"Genero"}>
                        <Form.Select id="genre"
                                     value={values.genre}
                                     placeholder=""
                                     onBlur={handleBlur}
                                     onChange={handleChange}>
                            <option value="M">Masculino</option>
                            <option value="F">Feminino</option>
                            <option value="O">Outro</option>
                        </Form.Select>
                    </FloatingLabel>
                </Col>
                <Col xs={12} lg={5}>
                    <FloatingLabel label={"Nome da mãe"}>
                        <Form.Control id="motherFullName"
                                      value={values.motherFullName}
                                      isInvalid={errors.motherFullName && touched.motherFullName}
                                      placeholder=""
                                      onBlur={handleBlur}
                                      onChange={handleChange}/>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.motherFullName}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col xs={12} lg={5}>
                    <FloatingLabel label={"Nome do pai"}>
                        <Form.Control id="fatherFullName"
                                      value={values.fatherFullName}
                                      isInvalid={errors.fatherFullName && touched.fatherFullName}
                                      placeholder=""
                                      onBlur={handleBlur}
                                      onChange={handleChange}/>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.fatherfullname}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>
            <h5>Dados para contato</h5>
            <Row className="mb-3 g-2">
                <Col xs={12} lg={8}>
                    <FloatingLabel label={"E-mail"}>
                        <Form.Control disabled
                                      placeholder=""
                                      value={values.email}/>
                    </FloatingLabel>
                </Col>
                <Col xs={12} lg={4}>
                    <FloatingLabel label={"Telefone celular"}>
                        <InputMask id="phone"
                                   mask="(99)9999-9999"
                                   value={values.phone}
                                   isInvalid={errors.phone && touched.phone}
                                   placeholder=""
                                   onBlur={handleBlur}
                                   onChange={handleChange}>
                            {(inputProps) => <Form.Control type="text" {...inputProps} />}
                        </InputMask>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.phone}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>
            <h5>Endereço</h5>
            <Row className="mb-3 g-2">
                <Col xs={12} lg={3}>
                    <FloatingLabel label={"CEP"}>
                        <InputMask id="zipCode"
                                   mask="99999-999"
                                   value={values.zipCode}
                                   isInvalid={errors.zipCode && touched.zipCode}
                                   placeholder=""
                                   onBlur={handleBlur}
                                   onChange={handleChange}>
                            {(inputProps) => <Form.Control type="text" {...inputProps} />}
                        </InputMask>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.zipCode}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col xs={8} lg={7}>
                    <FloatingLabel label={"Rua"}>
                        <Form.Control id="street"
                                      value={values.street}
                                      isInvalid={errors.street && touched.street}
                                      placeholder=""
                                      onBlur={handleBlur}
                                      onChange={handleChange}/>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.street}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col xs={4} lg={2}>
                    <FloatingLabel label={"Número"}>
                        <Form.Control id="number"
                                      value={values.number}
                                      isInvalid={errors.number && touched.number}
                                      placeholder=""
                                      onBlur={handleBlur}
                                      onChange={handleChange}/>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.number}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col xs={6} lg={4}>
                    <FloatingLabel label={"Complemento"}>
                        <Form.Control id="complement"
                                      value={values.complement}
                                      isInvalid={errors.complement && touched.complement}
                                      placeholder=""
                                      onBlur={handleBlur}
                                      onChange={handleChange}/>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.complement}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col xs={6} lg={3}>
                    <FloatingLabel label={"Bairro"}>
                        <Form.Control id="neighborhood"
                                      value={values.neighborhood}
                                      isInvalid={errors.neighborhood && touched.neighborhood}
                                      placeholder=""
                                      onBlur={handleBlur}
                                      onChange={handleChange}/>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.neighborhood}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col xs={8} lg={3}>
                    <FloatingLabel label={"Cidade"}>
                        <Form.Control id="city"
                                      value={values.city}
                                      isInvalid={errors.city && touched.city}
                                      placeholder=""
                                      onBlur={handleBlur}
                                      onChange={handleChange}/>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.city}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col xs={4} lg={2}>
                    <FloatingLabel label={"Estado (UF)"}>
                        <Form.Select id="state"
                                     value={values.state}
                                     isInvalid={errors.state && touched.state}
                                     placeholder=""
                                     onBlur={handleBlur}
                                     onChange={handleChange}>
                            <option value="ES">ES</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid" tooltip>
                            {errors.state}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
            </Row>
            <div className="d-grid mb-5">
                <Button variant="primary"
                        size="lg"
                        disabled={isSubmitting}
                        onClick={handleSubmit}>
                    {isLoading ? <Spinner/> : 'Salvar alterações'}
                </Button>
            </div>
        </>
    )
        ;
}

export default PersonalForm;