import React, {useEffect} from "react";
import {Stack, Container, Image} from "react-bootstrap";
import {Link} from "react-router-dom";
import Logo from "../assets/images/logo.png";
import Button from "react-bootstrap/Button";

const NotFound = () => {

    useEffect(() => {
        document.getElementById('root')
            .classList
            .add('d-flex', 'align-items-center', 'justify-content-center', 'min-vh-100');

        return () => {
            document.getElementById('root')
                .classList
                .remove('d-flex', 'align-items-center', 'justify-content-center', 'min-vh-100');
        }
    });

    return (
        <>
            <Container>
                <Stack className="justify-content-center align-items-center">
                    <Image src={Logo} className="img-fluid w-25"/>
                    <h1 className="text-secondary" style={{fontSize: '8rem'}}>404</h1>
                    <p>A página que você tentou acessar não encontrada.</p>
                    <Button as={Link} to="/" variant="primary">Voltar</Button>
                </Stack>
            </Container>
        </>
    )
}

export default NotFound;