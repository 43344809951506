import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {FloatingLabel, Container, Form, Button, Image, Alert, Spinner} from "react-bootstrap";
import {FaExclamationTriangle, FaEye, FaEyeSlash} from 'react-icons/fa';
import InputMask from "react-input-mask";
import {useFormik} from "formik";
import * as Yup from "yup";

import api from "../../helpers/api";
import Logo from "../../assets/images/logo.png";

const Recovery = () => {
    const [error, setError] = useState(null);
    const [tokenInput, setTokenInput] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [toast, setToast] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const {handleSubmit, values, handleChange, touched, errors} = useFormik({
        initialValues: {
            login: '',
            newPassword: '',
        },
        validationSchema: Yup.object().shape({
            login: Yup.string().required("Informe seu CPF"),
            newPassword: Yup.string().required("Informe sua senha"),
        }),
        onSubmit: async (v) => {
            const {login, newPassword, token} = v;
            setLoading(true);
            try {
                if (!tokenInput) {
                    await api.post('app/usuario/senha/criar', {
                        login, newPassword
                    })
                    setTokenInput(true);
                } else {
                    await api.post('app/usuario/senha/criar', {
                        login, token
                    })
                    setToast({
                        show: true,
                        title: "Sucesso",
                        variant: "success",
                        content: "Senha atualizada com sucesso",
                        delay: toast.delay
                    })
                    navigate('/login');
                }
            } catch (err) {
                if (err.response) {
                    const {errors} = {...err.response.data};
                    Object.entries(errors).forEach(([key, value]) => {
                        setError(value, key);
                    })
                } else {
                    setError('Erro desconhecido');
                }
            }
            setLoading(false);
        }
    })

    return (
        <Container className={'d-flex justify-content-center align-items-center vh-100'}>
            <form onSubmit={handleSubmit}>
                <div className={'d-flex flex-column gap-3'} style={{maxWidth: 480}}>
                    <div className={'text-center'}>
                        <Image src={Logo} className={'img-fluid w-50 mb-2'}/>
                        <h5>Criar uma nova senha</h5>
                        <p>
                            Se esse for o seu primeiro acesso você poderá criar a sua senha por aqui. Caso você tenha
                            perdido sua senha atual é possível solicitar a criação de uma nova senha de acesso à sua
                            conta.
                        </p>
                    </div>
                    {!tokenInput &&
                        <React.Fragment>
                            <Form.Group>
                                {error && (
                                    <Alert variant="danger">
                                        <FaExclamationTriangle className="me-2"/>
                                        {error}
                                    </Alert>
                                )}
                                <FloatingLabel label={"CPF"}>
                                    <InputMask
                                        placeholder={'CPF'}
                                        mask="999.999.999-99"
                                        id='login'
                                        name='login'
                                        value={values.login}
                                        onChange={handleChange}
                                        className="border-primary"
                                    >
                                        {(inputProps) => <Form.Control type="text" {...inputProps} />}
                                    </InputMask>
                                </FloatingLabel>
                                <Form.Text className="text-muted"></Form.Text>
                                {touched.login && errors.login ? (
                                    <span className="error">{errors.login}</span>
                                ) : null}
                            </Form.Group>
                            <Form.Group className="position-relative">
                                <FloatingLabel label={"Senha"}>
                                    <Form.Control
                                        type={showPassword ? "text" : "password"}
                                        placeholder="Digite sua senha"
                                        id="newPassword"
                                        name="newPassword"
                                        value={values.password}
                                        onChange={handleChange}
                                        maxLength={6}
                                        className="border-primary"
                                    />
                                </FloatingLabel>
                                <Button
                                    variant="primary"
                                    onClick={() => setShowPassword(!showPassword)}
                                    style={{
                                        position: "absolute",
                                        right: 10,
                                        top: "50%",
                                        transform: "translateY(-50%)",
                                        border: "none",
                                        background: "transparent",
                                        color: "gray",
                                    }}
                                >
                                    {showPassword ? <FaEyeSlash/> : <FaEye/>}
                                </Button>
                                <Form.Text className="text-muted"></Form.Text>
                                {touched.password && errors.password ? (
                                    <span className="error">{errors.password}</span>
                                ) : null}
                            </Form.Group>
                        </React.Fragment>
                    }
                    {tokenInput &&
                        <Form.Group>
                            {tokenInput &&
                                <Alert variant={'success'}>Insira abaixo o código enviado por e-mail e SMS.</Alert>}
                            <FloatingLabel label={"Token"}>
                                <Form.Control type="text"
                                              placeholder="Digite o código"
                                              value={values.token}
                                              id='token'
                                              name='token'
                                              maxLength={6}
                                              className="border-primary"
                                              onChange={handleChange}/>
                            </FloatingLabel>
                            <Form.Text className="text-muted"></Form.Text>
                        </Form.Group>
                    }
                    <Button className={"btn-primary"}
                            type="submit"
                            size={"lg"}
                    >
                        {loading ? <Spinner animation="border"/> : "Criar senha"}
                    </Button>
                    <Link className={'text-dark text-center my-3'}
                          to={'/'}>Voltar para login
                    </Link>
                </div>
            </form>
        </Container>
    )
}

export default Recovery;