import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";

const External = () => {
    const {user} = useAuth();

    if (user) {
        return <Navigate to={"dashboard"}/>
    }

    return (
            <React.Fragment>
                <Outlet/>
            </React.Fragment>
    );
}

export default External;