export function register() {
    if ("serviceWorker" in navigator) {
        window.addEventListener("load", () => {
            navigator.serviceWorker.register("/serviceWorker.js").then(
                (registration) => {
                    console.log("Service Worker registrado:", registration);
                },
                (error) => {
                    console.error("Erro ao registrar Service Worker:", error);
                }
            );
        });
    }
}