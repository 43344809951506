import React, {useEffect, useState} from 'react';
import {
    Card,
    CardBody,
    Image,
    Row,
    Col
} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import api from '../helpers/api';
import {useAuth} from '../hooks/useAuth';
import FigVideoDoctor from '../assets/images/fig-video-doctor.png';
import {firstName} from "../helpers/utils";
import AppointmentList from "../components/AppointmentList";

const Dashboard = () => {
    const {user} = useAuth();
    const [isLoading, setLoading] = useState(false);
    const [subscriber, setSubscriber] = useState([]);
    const [appointments, setAppointments] = useState([]);

    const getSubscribers = async () => {
        const URL_ = `/assinante/${user?.OwnerId}`;
        const METHOD = 'GET';
        const resp = await api({url: URL_, method: METHOD});
        setSubscriber(resp.data.data);
    };

    const fetchAppointments = async () => {
        try {
            if (isLoading) return;
            setLoading(true);

            const res = await api({
                url: '/video/atendimentos',
                method: 'GET',
                params: {
                    patientId: user.OwnerId,
                    status: '0,1,2',
                    origin: 'conexa',
                },
            });

            const {data} = res.data;
            setAppointments(data);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getSubscribers().then(() => {
            fetchAppointments().then();
        });
    }, []);

    return (
        <>
            <h1 className={'h5'}>
                Olá, <span className={'text-secondary'}>{firstName(subscriber?.FullName)}</span>!
                <span className={'d-block'}>Como podemos te ajudar?</span>
            </h1>

            <Row className={'mt-4 justify-content-center'}>
                <Col lg={6} xs={12} className={'mb-3'}>
                    <Card className={'bg-body border-light shadow shadow-sm'}>
                        <CardBody as={Link} to={'/atendimento/pa'} className={'text-decoration-none'}>
                            <h5 className={'text-secondary mb-0'}>PA Digital</h5>
                            <p>Atendimento com clínicos gerais sem agendamento.</p>
                            <div className={'justify-content-center d-flex'}>
                                <Image src={FigVideoDoctor} className={'img-fluid'} style={{'maxHeight': '140px'}}
                                       alt={'PA digital'}/>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            {subscriber.DrawEnabled && (
                <Card className="bg-body-tertiary border-0 shadow-sm">
                    <CardBody>
                        <span className="small">
                            Você esta participando de sorteio através do seguinte número da sorte:
                        </span>
                        <strong>{subscriber.DrawNumber}</strong>
                    </CardBody>
                </Card>
            )}

            <h2 className="h5 mt-4">Próximo(s) atendimento(s)</h2>
            <AppointmentList appointments={appointments}
                             delay={10000}
                             onRefresh={fetchAppointments}/>
        </>
    );
};

export default Dashboard;
