import {useCallback, useState} from 'react';
import PropTypes from "prop-types";

/**
 * A custom React hook for interacting with localStorage.
 * @param {string} keyName - The key to use in localStorage.
 * @param {*} initialValue - The initial value stored if no value exists in localStorage.
 * @returns {[*, function]} - Returns the stored value and a function to update it.
 */
const useLocalStorage = (keyName, initialValue) => {
    // State to store the current value
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(keyName);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.error("Error reading localStorage key:", error);
            return initialValue;
        }
    });

    // Function to update the value in state and localStorage
    const setValue = useCallback((newValue) => {
        try {
            const valueToStore = newValue instanceof Function ? newValue(storedValue) : newValue;
            setStoredValue(valueToStore);
            window.localStorage.setItem(keyName, JSON.stringify(valueToStore));
        } catch (error) {
            console.error("Error saving to localStorage:", error);
        }
    }, [keyName, storedValue]);

    return [storedValue, setValue];
};

useLocalStorage.propTypes = {
    keyName: PropTypes.string.isRequired,
    initialValue: PropTypes.any,
};

export default useLocalStorage;
