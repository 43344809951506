import React, {useEffect, useState} from 'react';
import {Nav, Form} from 'react-bootstrap';
import {CiCalendar, CiCircleCheck, CiCircleRemove} from 'react-icons/ci';
import api from "../../helpers/api";
import {useAuth} from "../../hooks/useAuth";
import AppointmentList from "../../components/AppointmentList";
import {useToast} from "../../hooks/useToast";

const History = () => {
    const toast = useToast();
    const {user} = useAuth();
    const [isLoading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState('0,1,2');
    const [appointments, setAppointments] = useState([]);
    const [dependents, setDependents] = useState([]);
    const [patientId, setPatientId] = useState(user.OwnerId);
    const [reload, setReload] = useState(null);

    const fetchDependents = async () => {
        try {
            const {OwnerId, FullName} = user;
            const resp = await api({
                url: `/titular/${OwnerId}/dependentes`,
                method: 'GET',
                params: {
                    ownerId: OwnerId,
                }
            });
            const {data} = resp.data;
            data.unshift({
                Id: OwnerId,
                FullName: FullName,
            });
            setDependents(data);
        } catch (e) {
            if (e.response && e.response.status >= 400 && e.response.status < 500) {
                toast.showToast(e.response.data.errors[0], 'error');
            }
        }
    };

    const fetchData = async () => {
        try {
            if (isLoading) return;
            setLoading(true);

            const res = await api({
                url: '/video/atendimentos',
                method: 'GET',
                params: {
                    patientId,
                    status: activeTab,
                    origin: 'conexa',
                },
            });

            const {data} = res.data;
            setAppointments(data);
        } catch (e) {
            if (e.response && e.response.status >= 400 && e.response.status < 500) {
                toast.showToast(e.response.data.errors[0], 'error');
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData().then();
    }, [activeTab, patientId, reload]);

    useEffect(() => {
        fetchDependents().then();
    }, []);

    return (
        <>
            <h1 className="h2">Histórico de atendimentos</h1>
            <p>Relação de atendimentos solicitados, realizados e cancelados</p>

            {dependents.length > 0 && (
                <Form.Group controlId="patientId" className="my-4">
                    <Form.Label column={0}>Paciente</Form.Label>
                    <Form.Select id="patientId"
                                 size="lg"
                                 onChange={e => setPatientId(e.target.value)}>
                        {dependents.map(({Id, FullName}, key) => {
                            return <option key={key.toString()} value={Id}>{FullName.toUpperCase()}</option>
                        })}
                    </Form.Select>
                </Form.Group>
            )}

            <Nav fill variant="underline"
                 defaultActiveKey={'0,1,2'}
                 onSelect={setActiveTab}>
                <Nav.Item>
                    <Nav.Link eventKey={'0,1,2'}>
                        <CiCalendar size={22} className="text-secondary"/><span
                        className="hidden-xs mx-1">Agendados</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={7}>
                        <CiCircleCheck size={22} className="text-success"/><span
                        className="hidden-xs mx-1">Realizados</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={'8,11'}>
                        <CiCircleRemove size={22} className="text-danger"/><span
                        className="hidden-xs mx-1">Cancelados</span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <AppointmentList appointments={appointments}
                             delay={15000}
                             onRefresh={(timestamp) => setReload(timestamp)}/>
        </>
    );
};

export default History;