import React, {useEffect, useState} from "react";
import {Outlet, Link, useLocation} from "react-router-dom";
import {Nav} from "react-bootstrap";

import {useAuth} from "../../hooks/useAuth";
import {firstName} from "../../helpers/utils";
import {CiUser, CiLock} from "react-icons/ci";

const Profile = () => {
    const location = useLocation();
    const {user} = useAuth();
    const {FullName} = user;
    const [activeTab, setActiveTab] = useState('dados-cadastrais');

    useEffect(() => {
        const pathSegments = location.pathname.split("/").filter(Boolean);
        const lastSegment = pathSegments[pathSegments.length - 1];
        setActiveTab(['dados-cadastrais', 'credenciais'].includes(lastSegment) ? lastSegment : 'dados-cadastrais');
    }, [location]);

    return (
        <>
            <h1 className="h4">Olá, <span className="text-secondary">{firstName(FullName)}</span>. </h1>
            <p>aqui você encontra informações contratuais e dados de acesso</p>

            <Nav fill
                 variant="underline"
                 activeKey={activeTab}
                 className="mb-4">
                <Nav.Item>
                    <Nav.Link as={Link} to="/conta/perfil/dados-cadastrais" eventKey={'dados-cadastrais'}>
                        <CiUser size={22}/><span className="hidden-xs mx-1">Dados cadastrais</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/conta/perfil/credenciais" eventKey="credenciais">
                        <CiLock size={22}/><span className="hidden-xs mx-1">Credênciais</span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            <Outlet/>
        </>
    )
}

export default Profile;