import React, {createContext, useState, useContext, useCallback} from 'react';
import {Toast, ToastContainer} from 'react-bootstrap';

// Cria o contexto
const ToastContext = createContext();

// Provider do ToastContext
export const ToastProvider = ({_container, children}) => {
    const [toasts, setToasts] = useState([]);

    // Método para exibir o toast
    const showToast = useCallback((message, variant = 'primary', delay = 3000) => {
        const id = Date.now();
        setToasts((prevToasts) => [
            ...prevToasts,
            {id, message, variant, delay, _container},
        ]);
        setTimeout(() => removeToast(id), delay);
    }, []);

    // Método para remover o toast
    const removeToast = useCallback((id) => {
        setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
    }, []);

    return (
        <ToastContext.Provider value={{showToast}}>
            {children}
            <ToastContainer position="top-end" className="p-3 position-fixed" style={{ zIndex: 9999 }} {..._container}>
                {toasts.map((toast) => (
                    <Toast
                        key={toast.id}
                        onClose={() => removeToast(toast.id)}
                        bg={toast.variant}
                        autohide
                        animation
                    >
                        <Toast.Body className={'text-white'}>{toast.message}</Toast.Body>
                    </Toast>
                ))}
            </ToastContainer>
        </ToastContext.Provider>
    );
};

// Hook para consumir o ToastContext
export const useToast = () => {
    const context = useContext(ToastContext);
    if (!context) {
        throw new Error('useToast deve ser usado dentro de um ToastProvider');
    }
    return context;
};