import React from 'react';
import AppRoutes from "./AppRoutes";
import AuthProvider from "./hooks/useAuth";
import {ToastProvider} from "./hooks/useToast";

function App() {
    return (
        <ToastProvider _container={{position: 'bottom-center'}}>
            <AuthProvider initialPath='/' loginPath='/'>
                <AppRoutes/>
            </AuthProvider>
        </ToastProvider>
    );
}

export default App;
