import React from "react";
import {Badge} from "react-bootstrap";

const AppointmentLabel = ({status}) => {
    let variant;
    let label;
    switch (status) {
        default:
            variant = 'warning'
            label = 'Solicitado'
            break;
        case 1:
            variant = 'info'
            label = 'Aceito'
            break;
        case 2:
            variant = 'success'
            label = 'Em andamento'
            break;
        case 7:
            variant = 'success'
            label = 'Concluído'
            break;
        case 8:
            variant = 'danger'
            label = 'Cancelado'
            break;
    }
    return (
        <Badge pill bg={variant}>{label}</Badge>
    )
}
export default AppointmentLabel;