import React from 'react';
import {Navigate, Outlet} from "react-router-dom";
import Container from "react-bootstrap/Container";

import {useAuth} from "../../hooks/useAuth";
import AppNavBar from "../../components/AppNavBar";
import AppFooter from "../../components/AppFooter";

const Internal = () => {
    const {user} = useAuth();

    if (!user) {
        return <Navigate to={"/"}/>
    }

    return (
        <React.Fragment>
            <AppNavBar/>
            <Container style={{paddingTop: '74px'}} className={"px-4"}>
                <Outlet/>
            </Container>
            <AppFooter/>
        </React.Fragment>
    );
}

export default Internal;