import React, {useEffect, useState} from 'react';
import {Image, Stack, Form, Button, Spinner} from 'react-bootstrap';
import {useFormik} from 'formik';
import * as yup from 'yup';
import {useNavigate} from 'react-router-dom';

// CUSTOM
import api from '../../helpers/api';
import {useAuth} from '../../hooks/useAuth';
import FigVideoDoctor from '../../assets/images/fig-video-doctor.png';
import {useToast} from "../../hooks/useToast";

const Immediate = () => {
    const [dependents, setDependents] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const {user} = useAuth();
    const navigate = useNavigate();
    const toast = useToast();

    const fetchDependents = async () => {
        try {
            const {OwnerId, FullName} = user;
            const resp = await api({
                url: `/titular/${OwnerId}/dependentes`,
                method: 'GET',
                params: {
                    ownerId: OwnerId,
                }
            });
            const {data} = resp.data;
            data.unshift({
                Id: OwnerId,
                FullName: FullName,
            });
            setDependents(data);
        } catch (e) {
            if (e.response && e.response.status >= 400 && e.response.status < 500) {
                toast.showToast(e.response.data.errors[0], 'error');
            }
        }
    };

    const {handleSubmit, values, errors, touched, handleChange, isSubmitting} = useFormik({
        initialValues: {
            patientId: user.OwnerId,
        },
        validationSchema: yup.object().shape({
            patientId: yup.string().required('Informe o nome do paciente'),
        }),
        onSubmit: async (val) => {
            let gotoWaitingRoom = false;
            try {
                if (isLoading) return;
                setLoading(true);
                await api({
                    url: `/video/atendimento`,
                    method: 'POST',
                    data: {
                        patientId: val.patientId,
                        professionalType: 'DOCTOR'
                    }
                });
                setLoading(false);
                gotoWaitingRoom = true;
                navigate(`/atendimento/historico`);
            } catch (e) {
                if (e.response && e.response.status >= 400 && e.response.status < 500) {
                    toast.showToast(e.response.data.errors[0], 'error');
                    gotoWaitingRoom = (e.response.status === 422);
                }
            } finally {
                if (gotoWaitingRoom) {
                    navigate(`/atendimento/historico`);
                }
                setLoading(false);
            }
        }
    });

    useEffect(() => {
        fetchDependents().then();
    }, []);

    return (<>
        <h1 className="h2"><span className=" text-secondary">Pronto Atendimento</span> Digital</h1>
        <p>Atendimento realizado por Clínico Geral, sem a necessidade de marcação de horário. Solicite atendimento e
            aguarde ser atendimento.
        </p>

        <Stack className="my-4" gap={3}>
            <div className={'text-center'}>
                <Image src={FigVideoDoctor}
                       className={'img-fluid'}
                       style={{'maxHeight': '200px'}}
                       alt={'PA digital'}/>
            </div>

            {dependents.length > 0 && (
                <Form.Group controlId="patientId">
                    <Form.Label column={0}>Quem receberá o atendimento?</Form.Label>
                    <Form.Select id="patientId"
                                 size="lg"
                                 defaultValue={values.patientId}
                                 onChange={handleChange}
                                 isInvalid={touched.patientId && errors.patientId}>
                        {dependents.map(({Id, FullName}, key) => {
                            return <option key={key.toString()} value={Id}>{FullName.toUpperCase()}</option>
                        })}
                    </Form.Select>
                </Form.Group>
            )}

            <Button size={'lg'}
                    variant={'primary'}
                    disabled={isSubmitting}
                    onClick={handleSubmit}>
                {isSubmitting ? <Spinner/> : 'SOLICITAR ATENDIMENTO'}
            </Button>
            <div>
                <h5 className="mb-0">Atenção!!!</h5>
                <p className="small text-justify">
                    Você entrará em uma fila e será notificado por <strong>e-mail e SMS</strong> com até <strong>5
                    minutos</strong> de antecedência. Fique atento para não perder o seu atendimento.
                </p>
            </div>
        </Stack>
    </>);
};

export default Immediate;