import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Image, Table} from "react-bootstrap";
import moment from "moment/moment";
import {CiVideoOn} from "react-icons/ci";
import {FaCalendarXmark} from "react-icons/fa6";

import AppointmentLabel from "./AppointmentLabel";
import FigNoResult from "../assets/images/fig-wrong.png";
import api from "../helpers/api";
import AppTooltip from "./AppTooltips";
import {useToast} from "../hooks/useToast";

const AppointmentList = ({appointments, onRefresh, delay=3000}) => {
    const navigate = useNavigate();
    const toast = useToast();

    const gotoWaitingRoom = (appointmentId) => {
        navigate(`/atendimento/sala-de-espera/${appointmentId}`);
    }

    const cancelAppointment = async (appointmentId) => {
        try {
            const prompt = window.confirm('Tem certeza que deseja cancelar o atendimento?');
            if (!prompt) return;
            await api.delete(`video/atendimento/${appointmentId}`);
            toast.showToast('Atendimento Cancelado', 'warning');
            if (onRefresh) {
                onRefresh(+ Date.now());
            }
        } catch (e) {
            if (e.response && e.response.status >= 400) {
                const {errors} = {...e.response.data};
                toast.showToast(`Não foi possível cancelar o atendimento. "${errors[0]}"`, 'danger');
            }
        }
    }

    useEffect(() => {
        if (!onRefresh) return;
        const intervalo = setInterval(() => {
            onRefresh(+ Date.now());
        }, delay);
        return () => clearInterval(intervalo);
    }, []);

    return (
        <>
            {
                appointments.length === 0
                    ? (
                        <div className="text-center p-5">
                            <Image src={FigNoResult} className={'img-fluid'} style={{'maxHeight': '200px'}}/>
                            <p>Nada por aqui!</p>
                        </div>
                    )
                    : (
                        <Table responsive="sm" className="mt-4" hover>
                            <tbody>
                            {appointments.map((item, key) => {
                                return (
                                    <tr key={key.toString()}>
                                        <td className="text-nowrap align-content-center">#{item.id}</td>
                                        <td className="text-nowrap align-content-center">{moment(item.scheduledAt).format('DD/MM/YY HH:mm')}</td>
                                        <td className="text-nowrap align-content-center w-75">{item.specialty}</td>
                                        <td className="text-nowrap align-content-center">
                                            <AppointmentLabel status={item.status}/>
                                        </td>
                                        <td className="text-nowrap align-content-center">
                                            {[2].includes(item.status) && (
                                                <AppTooltip title="Entrar na sala de espera">
                                                    <Button variant="success"
                                                            size="sm"
                                                            onClick={() => gotoWaitingRoom(item.id)}
                                                            disabled={item.status === 0}>
                                                        <CiVideoOn size={22}/>
                                                    </Button>
                                                </AppTooltip>
                                            )}
                                            {[0, 1, 2].includes(item.status) && (
                                                <AppTooltip title="Cancelar atendimento">
                                                    <Button variant="danger"
                                                            size="sm"
                                                            onClick={() => cancelAppointment(item.id)}>
                                                        <FaCalendarXmark/>
                                                    </Button>
                                                </AppTooltip>
                                            )}
                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </Table>
                    )
            }
        </>
    )
}
export default AppointmentList;